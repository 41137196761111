var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('div',{staticClass:"home-nav"},[_c('div',{class:_vm.data.length <= 5
        ? 'list'
        : _vm.data.length == 6
        ? 'list1'
        : _vm.data.length == 7
        ? 'list2'
        : _vm.data.length == 8
        ? 'list2'
        : 'list3'},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.jump(item)}}},[_c('img',{attrs:{"src":item.imgUrl,"alt":""}}),_c('p',[_vm._v(_vm._s(item.title))])])}),0),_c('van-popup',{model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"msg"},[_c('img',{attrs:{"src":_vm.groupImage,"alt":""}})])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }