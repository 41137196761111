<template>
  <div class="home-nav" v-if="data">
    <div
      :class="
        data.length <= 5
          ? 'list'
          : data.length == 6
          ? 'list1'
          : data.length == 7
          ? 'list2'
          : data.length == 8
          ? 'list2'
          : 'list3'
      "
    >
      <div
        class="item"
        v-for="(item, index) in data"
        :key="index"
        @click="jump(item)"
      >
        <img :src="item.imgUrl" alt="" />
        <p>{{ item.title }}</p>
      </div>
    </div>
    <van-popup v-model="show">
      <div class="msg">
        <!-- <p>{{shopMsg.shopkeeperContact}}</p> -->
        <img :src="groupImage" alt="" />
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      show: false,
      groupImage: "",
    };
  },
  methods: {
    //跳转链接
    jump(item) {
      if (
        item.url.includes(".png") ||
        item.url.includes(".jpg") ||
        item.url.includes(".jpeg")
      ) {
        this.groupImage = item.url;
        this.show = true;
      } else {
        window.location.href = item.url;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-nav {
  width: 100%;
  margin-top: 18px;
  /deep/ .van-hairline::after {
    border: none !important;
  }
  .msg {
    padding: 10px;
    img {
      width: 100%;
    }
  }
  // border-bottom: 1px solid #eaeaea;
  .list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 8px 0;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 36px;
        height: 36px;
      }
      p {
        font-size: 12px;
        line-height: 12px;
        margin-top: 10px;
        color: #666;
      }
    }
  }
  .list1 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 8px 0;
    .item {
      width: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 10px;
      img {
        width: 36px;
        height: 36px;
      }
      p {
        font-size: 12px;
        line-height: 12px;
        margin-top: 10px;
        color: #666;
      }
    }
  }
  .list2 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 8px 0;
    .item {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 10px;
      img {
        width: 36px;
        height: 36px;
      }
      p {
        font-size: 12px;
        line-height: 12px;
        margin-top: 10px;
        color: #666;
      }
    }
  }
  .list3 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 8px 0;
    .item {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 10px;
      img {
        width: 36px;
        height: 36px;
      }
      p {
        font-size: 12px;
        line-height: 12px;
        margin-top: 10px;
        color: #666;
      }
    }
  }
}
</style>