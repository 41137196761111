<template>
  <div class="home-list">
    <van-tabs v-model="active" swipeable @click="changeTab">
      <van-tab
        v-for="(item, index) in tabData"
        :title="item.title"
        :key="index"
      >
        <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh">
          <van-list
            v-model="isUpLoading"
            :finished="upFinished"
            error-text="请求失败，点击重新加载"
            :immediate-check="false"
            finished-text="没有更多啦"
            @load="onLoad"
          >
            <div class="list">
              <van-cell>
                <div
                  class="item"
                  @click="detail(item)"
                  v-for="(item, index) in contentData"
                  :key="index"
                >
                  <div class="left">
                    <img :src="item.coverImage" alt="" />
                    <div v-if="item.estimateCommission != 0">
                      <p>预计返{{ item.estimateCommission * 100 }}元</p>
                    </div>
                  </div>
                  <div class="right">
                    <div class="name">
                      <p>{{ item.title }}</p>
                    </div>
                    <div class="price">
                      <p>{{ item.subTitle }}</p>
                    </div>
                    <div class="boo">
                      <div class="date">
                        <div class="date-left">
                          <p v-if="item.merchantName">
                            {{ item.merchantName }}
                          </p>
                          <i v-if="item.merchantName"></i>
                          <p>{{ item.updateTime }}</p>
                        </div>
                      </div>
                      <div class="num">
                        <van-icon size="16px" color="#999" name="eye-o" /><span
                          >{{ item.displayHits }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </van-cell>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { contentList, channelList, shopContentList } from "../../../utils/api";
export default {
  props: ["shopId", "shopMsg"],
  data() {
    return {
      isUpLoading: false,
      upFinished: false,
      isDownLoading: false,
      active: 0,
      dataList1: [],
      tabData: [],
      contentData: [],
      checkCode: "",
      pageNo: 1,
      pageSize: 15,
      checkItem: {},
    };
  },
  watch: {},
  created() {},
  async mounted() {
    await this.channelList();
  },
  methods: {
    onDownRefresh() {
      console.log(12);
      this.pageNo = 1;
      this.isUpLoading = true;
      this.upFinished = false; // 不写这句会导致你上拉到底过后在下拉刷新将不能触发下拉加载事件
      if (this.checkItem.type == 1) {
        this.contentList(); // 加载数据
      } else {
        this.shopContentList(); // 加载数据
      }
    },
    onLoad() {
      console.log(123, ";;;;;");
      setTimeout(() => {
        this.pageNo++;
        if (this.checkItem.type == 1) {
          this.contentList(); // 加载数据
        } else {
          this.shopContentList(); // 加载数据
        }
      }, 1000);
    },
    //跳转文章详情
    detail(item) {
      console.log(item);
      if (item.contentType == "shop") {
        this.$router.push({
          path: `/${this.shopMsg.shopAlias}/detailSelf`,
          query: {
            contentId: item.contentId,
          },
        });
      } else {
        this.$router.push({
          path: `/${this.shopMsg.shopAlias}/detail`,
          query: {
            contentId: item.contentId,
          },
        });
      }
    },
    //点击tab
    async changeTab(val) {
      this.pageNo = 1;
      this.tabData.map((item, index) => {
        if (index === val) {
          this.checkItem = item;
        }
      });
      this.checkCode = this.checkItem.code;
      if (this.checkItem.type == 1) {
        console.log("5555");
        await this.contentList();
        this.isUpLoading = false;
        this.upFinished = false;
      } else {
        console.log("66666");
        await this.shopContentList();
        this.isUpLoading = false;
        this.upFinished = false;
      }
    },
    //文章列表----店铺频道type=2
    async shopContentList() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let params = {
        channelCode: this.checkCode,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        shopId: this.shopId,
      };
      console.log(this.upFinished, ",,,");
      await shopContentList(params)
        .then((res) => {
          this.$toast.clear();
          console.log(this.pageNo, "pppp");
          if (this.pageNo === 1) {
            this.contentData = res.data;
          } else {
            if (res.data.length) {
              res.data.map((item) => {
                this.contentData.push(item);
              });
            }
          }

          if (res.data.length <= 0) {
            // 加载结束
            this.upFinished = true;
            return;
          }
          if (this.contentData.length < this.pageSize) {
            // 最后一页不足5条的情况
            this.upFinished = true;
          }

          this.isUpLoading = false;
        })
        .finally(() => {
          this.isDownLoading = false;
          this.isUpLoading = false;
        });
    },
    //文章列表----平台频道type=1
    async contentList() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let params = {
        channelCode: this.checkCode,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        shopId: this.shopId,
      };
      await contentList(params)
        .then((res) => {
          this.$toast.clear();
          if (this.pageNo === 1) {
            this.contentData = res.data;
          } else {
            if (res.data.length) {
              res.data.map((item) => {
                this.contentData.push(item);
              });
            }
          }

          if (res.data.length === 0) {
            // 加载结束
            this.upFinished = true;
            return;
          }
          if (res.data.length < this.pageSize) {
            // 最后一页不足5条的情况
            this.upFinished = true;
          }

          this.isUpLoading = false;
        })
        .finally(() => {
          this.isDownLoading = false;
          this.isUpLoading = false;
        });
    },
    //频道列表
    async channelList() {
      let params = {
        shopId: this.shopId,
      };
      await channelList(params).then((res) => {
        this.tabData = res.data;
        this.changeTab(0);
      });
    },
  },
};
</script>
<style scoped lang="scss">
$primary-color: #ff804d;
.home-list {
  margin-top: 15px;
  // padding: 0 0 100px;
  /deep/ .van-tabs__wrap {
    // border-bottom: 2px solid #eaeaea;
  }
  /deep/ .van-cell {
    padding: 0;
    font-size: 12px !important;
    line-height: 12px !important;
    color: #999 !important;
  }
  /deep/ .van-tabs--line .van-tabs__wrap {
    height: 32px;
  }
  /deep/ .van-tab {
    font-size: 12px;
    line-height: 17px;
    color: #666666;
  }
  /deep/ .van-tab--active {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
  }
  /deep/ .van-tabs__line {
    display: none;
  }
}
.list {
  width: 100%;
  padding: 0 15px;
  .item {
    width: 100%;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    padding: 20px 0;
    .left {
      width: 80px;
      height: 80px;
      position: relative;
      img {
        width: 80px;
        height: 80px;
      }
      div {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 18px;
        background: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          white-space: nowrap;
          color: #fff;
          font-size: 20px;
          line-height: 10px;
          transform: scale(0.5);
        }
      }
    }
    .right {
      width: 100%;
      height: 100%;
      flex: 1;
      padding-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        p {
          font-size: 12px;
          line-height: 17px;
          color: #333;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: justify;
        }
      }
      .price {
        font-size: 12px;
        line-height: 12px;
        color: #e62828;
        margin: 3px 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: justify;
        margin-top: 10px;
      }
      .boo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
        .num {
          display: flex;
          align-items: center;
          span {
            padding-left: 3px;
            font-size: 12px;
            line-height: 12px;
            color: #999;
          }
        }
        .date {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .date-left {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            color: #999;
            line-height: 12px;
            i {
              width: 1px;
              height: 12px;
              background: #999;
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}
</style>
