<template>
  <div style="height: 100%">
    <div class="home" v-if="evolve" ref="homeref">
      <!--  顶部轮播图  -->
      <scroll-banner :data="images" />
      <!--  金刚位  -->
      <home-nav :data="navData" />
      <SearchList :shopId="shopId"></SearchList>
      <!-- tab切换文章列表 -->
      <tab-list
        :shopId="shopId"
        :shopMsg="shopMsg"
        v-if="shopMsg"
        ref="tablist"
      />
    </div>
    <tabBar :shopAlias="shopAlias" />
    <backTop />
    <customerService v-if="this.shopId" />
    <div class="putonrecord">
      <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023002612号-1</a>
    </div>
  </div>
</template>

<script>
import ScrollBanner from "././component/ScrollBanner.vue";
import HomeNav from "././component/Nav.vue";
import TabList from "././component/TabList.vue";
import SearchList from "././component/Search";
import tabBar from "../../component/tabBar";
import backTop from "../../component/backTop";
import customerService from "../../component/customerService";

import { bannerList, indexDiamond, getShopName } from "../../utils/api";

export default {
  components: {
    ScrollBanner,
    HomeNav,
    TabList,
    SearchList,
    tabBar,
    backTop,
    customerService,
  },
  data() {
    return {
      images: [],
      navData: [],
      isFirstEnter: false,
      evolve: null,
      shopId: "",
      shopAlias: "",
      shopMsg: {},
    };
  },
  created() {
    // window.parentMounted = false;
    // console.log(sessionStorage.getItem("useInfo"));
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "detail" || from.name == "detailSelf") {
      to.meta.isBack = true;
    }
    next();
  },
  async activated() {
    window.parentMounted = false;
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      this.images = [];
      this.navData = [];
      this.isFirstEnter = false;
      this.evolve = null;
      this.shopId = "";
      // let shopMsg = JSON.stringify(sessionStorage.getItem('shopMsg'))
      // this.shopId = shopMsg.id;
      // this.shopAlias = shopMsg.shopAlias;

      await this.getshopName();
      await this.bannerList();
      await this.indexDiamond();
    }
    this.$route.meta.isBack = false;
    this.isFirstEnter = false;
  },
  methods: {
    //获取店铺信息
    async getshopName() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await getShopName().then((res) => {
        // console.log(res)
        this.shopId = res.id;
        this.shopMsg = res;
        this.shopAlias = this.shopMsg.shopAlias;
        document.title = this.shopMsg.shopName;
      });
    },
    //banner图片接口
    async bannerList() {
      // console.log(this.shopId)
      let params = {
        shopId: this.shopId,
      };
      await bannerList(params).then((res) => {
        this.images = res.data;
      });
    },
    //金刚位接口
    async indexDiamond() {
      let params = {
        shopId: this.shopId,
      };
      await indexDiamond(params).then((res) => {
        this.navData = res.data;
        this.$toast.clear();
        this.evolve = true;
      });
    },
    scroll(e) {
       var scrollTop = this.$refs["homeref"].scrollTop; //滚动条的位置
      var windowHeitht = this.$refs["homeref"].clientHeight; //在页面上返回内容的可视高度
      var scrollHeight = this.$refs["homeref"].scrollHeight; //返回整个元素的高度（包括带滚动条的隐蔽的地方）
      if(Math.round(scrollTop) + windowHeitht == scrollHeight) {
        // this.$refs["tablist"].pageNo++
        // this.$refs["tablist"].shopContentList()
        console.log(this.$refs["tablist"].pageNo,e);
      }
    },
  },
  async mounted() {
    setTimeout(() => {
      console.log(this.$refs["homeref"], ";;;asdlad");
      this.$refs["homeref"].addEventListener("scroll", this.scroll, true);
    }, 2000);

    this.isFirstEnter = true;
  },
};
</script>

<style>
/* html,
body {
  height: 100%;
} */
.home {
  width: 100%;
  /* height: 100%; */
  /* overflow: auto; */
  padding-bottom: 100px;
}
img {
  width: 100%;
  height: 100%;
}
.putonrecord {
  width: 100%;
  text-align: center;
  color: #666;
}
.putonrecord a{
  color: #666;
}
</style>