<template>
  <div class="search" @click="changeSearch">
    <van-search
      v-model="value"
      shape="round"
      placeholder="搜索商品名/品牌/链接/口令"
    />
    <div class="hotSearch" v-if="list && list.length != 0">
      <p class="tit">热搜：</p>
      <div class="list">
        <span>足浴</span>
        <span>足浴</span>
        <span>足浴</span>
      </div>
    </div>
  </div>
</template>

<script>
import {topSearch} from "../../../utils/api"
export default {
  props:['shopId'],
  data() {
    return {
      value: "",
      list:null,
    };
  },
  mounted(){
    this.topSearch();
  },
  methods: {
    //跳转搜索
    changeSearch() {
      let shopAlias = window.location.pathname.split("/")[1];
      this.$router.push({
        path: `/${shopAlias}/search`,
      });
    },
    //热搜词
    topSearch(){
      let params = {
        shopId:this.shopId
      }
      topSearch(params).then(res => {
        console.log(res)
        this.list = res.data;
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin-top: 15px;
  padding: 0 15px;
  .hotSearch {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    .tit {
      // transform: scale(0.83);
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
    }
    .list {
      display: flex;
      align-items: center;
      span {
        font-size: 10px;
        transform: scale(1);
        font-weight: 400;
        color: #ffffff;
        line-height: 14px;
        padding: 0 5px;
        background: #ffbba0;
        border-radius: 8px;
        margin-right: 5px;
      }
    }
  }
}
/deep/ .van-field__left-icon .van-icon,
.van-field__right-icon .van-icon {
  color: #999;
}
/deep/ .van-search {
  padding: 5px 10px;
}
/deep/ .van-search__content--round {
  border-radius: 4px;
}
/deep/ .van-search .van-cell {
  padding: 2px;
}
/deep/ .van-search .van-cell {
  padding: 2px;
}
/deep/ .van-search {
  padding: 0;
  border-radius: 4px !important;
}
/deep/ .van-search__content {
  height: 32px;
  background: #fcfcfc;
  border-radius: 16px;
  border: 1px solid #eeeeee;
}
</style>