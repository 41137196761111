<template>
  <div style="padding: 0 15px">
    <van-swipe class="scroll-banner" :autoplay="3000" indicator-color="white" :stop-propagation="false">
      <van-swipe-item v-for="(item, index) in data" :key="index">
        <a href="#" target="_blank" @click.prevent="clickimg(item)">
          <img v-lazy="item.imgUrl" :alt="item.name"/>
        </a>
      </van-swipe-item>
    </van-swipe>
    <van-popup v-model="show">
      <div class="msg">
        <!-- <p>{{shopMsg.shopkeeperContact}}</p> -->
        <img :src="groupImage" alt="" />
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      show: false,
      groupImage: "",
    };
  },
  methods: {
    clickimg(item) {
    if (
      item.url.includes(".png") ||
      item.url.includes(".jpg") ||
      item.url.includes(".jpeg")
    ) {
      this.groupImage = item.url;
      this.show = true;
    } else {
      window.location.href = item.url;
    }
  },
  }
  
};
</script>

<style lang="scss">
.scroll-banner {
  /* width: 100%; */
  // padding: 0 15px;
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;
  height: 160px;
  /deep/ .van-swipe__track {
    width: 100% !important;
    // padding: 0 15px;
  }
  /deep/ .van-swipe-item {
    width: 100% !important;
    padding: 0 15px;
  }
  img {
    width: 100%;
    height: 150px;
    border-radius: 10px;
  }
  .msg {
    padding: 10px;
    img {
      width: 100%;
    }
  }
}
</style>